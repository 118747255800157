import React from 'react';
import { CircularProgress } from '@material-ui/core';
import Puzzle from 'react-image-puzzle';
import './App.scss'

function App() {
	//Loading
	const [loading, setLoading] = React.useState(false);
	// Set image
	const [image, setImage] = React.useState([]);
	//When puzzle is complete
	const [done, setDone] = React.useState(false)
	const handleDone = () => {
		setDone(true);
	}
	// Set puzzle level - default: 3
	const [level, setLevel] = React.useState(3);
	// Crop image
	function crop(url, aspectRatio) {
    
		// we return a Promise that gets resolved with our canvas element
		return new Promise(resolve => {
	
			// this image will hold our source image data
			const inputImage = new Image();
	
			// we want to wait for our image to load
			inputImage.onload = () => {
	
				// let's store the width and height of our image
				const inputWidth = inputImage.naturalWidth;
				const inputHeight = inputImage.naturalHeight;
	
				// get the aspect ratio of the input image
				const inputImageAspectRatio = inputWidth / inputHeight;
	
				// if it's bigger than our target aspect ratio
				let outputWidth = inputWidth;
				let outputHeight = inputHeight;
				if (inputImageAspectRatio > aspectRatio) {
					outputWidth = inputHeight * aspectRatio;
				} else if (inputImageAspectRatio < aspectRatio) {
					outputHeight = inputWidth / aspectRatio;
				}
	
				// calculate the position to draw the image at
				const outputX = (outputWidth - inputWidth) * .5;
				const outputY = (outputHeight - inputHeight) * .5;
	
				// create a canvas that will present the output image
				const outputImage = document.createElement('canvas');
	
				// set it to the same size as the image
				outputImage.width = outputWidth;
				outputImage.height = outputHeight;
	
				// draw our image at position 0, 0 on the canvas
				const ctx = outputImage.getContext('2d');
				ctx.drawImage(inputImage, outputX, outputY);
				resolve(outputImage);
				const puzzleImg = new Image();
				puzzleImg.onload = () => {
					setImage({
						url: puzzleImg.src
					});
				}
				puzzleImg.src = outputImage.toDataURL('image/jpg').replace("image/jpg", "image/octet-stream")
			};
	
			// start loading our image
			inputImage.src = url;
		})
	}

	// Upload new image
	const handleUpload = e => {
		setDone(false);
		setLoading(true);
		setImage([])
		const image = e.target.files[0];
		const imgURL = URL.createObjectURL(image);
		crop(imgURL, 1).then(console.log(image))
		setLoading(false)
	}

	//Chose random image
	const imgArray = ['img1', 'img2', 'img3', 'img4', 'img5', 'img6', 'img7', 'img8', 'img9', 'img10', 'img11']
	const getRandomImage = () => {
		setImage([]);
		const path = '/img/';
		let num = Math.floor(Math.random() * imgArray.length)
		let randImage = imgArray[num]
		let image = path + randImage + '.jpg'
		crop(image, 1)
	}

	const startNewPuzzle = () => {
		window.location.reload(true);
	}

	return (
		<div className="container">
		{loading ? <CircularProgress /> : null}
			<div id="puzzleWrap">
				<Puzzle image={image.url} className="puzzle" size={400} onDone={handleDone}/>
				{loading === true ? <CircularProgress /> : null}
			</div>
			{done === true ? (
				<div className="done-message">
					<h2>Way to go, you solved the puzzle! Try another!</h2>
					<button className="uploadBtn" onClick={startNewPuzzle}>Start again</button>
				</div>
			) : (
			<div className="select-image">
				<input type="file" name="imgUpload" id="imgUpload" accept=".jpg, .jpeg, .png" onChange={handleUpload}/>
				<label className="uploadBtn" htmlFor="imgUpload" >Upload</label>
				<label className="uploadBtn" onClick={getRandomImage}>Random</label>
			</div>
			)}
		</div>
	)
}

export default App;
